import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { AppContext } from "../../contexts/AppContext/AppContext";
import TranslateIcon from "@material-ui/icons/Translate";
import { ValueChainsContext } from "../../contexts/ValueChainsContext/ValueChainsContext";
function LanguageChange() {
  const { saveOfflineLanguage, appLanguage, changeAppLang,appLanguageShortName } =
    useContext(AppContext);

  const { languages } = useContext(ValueChainsContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="languageBtn">
      <Button
        size="small"
        variant="contained"
        color="primary"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <TranslateIcon style={{ marginRight: 10 }} /> {appLanguageShortName}
      </Button>
      
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((val) => {
          return (
            <MenuItem
              onClick={() => {
                handleClose();
                changeAppLang(JSON.stringify(val.id), val.url, val.vc_url,val.short_name);
              }}
            >
              {val.name} {appLanguage === JSON.stringify(val.id) && "(Active)"}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );

 
}

export default LanguageChange;
