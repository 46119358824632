import React from 'react'

function Preheader() {
    return (
        <div style={{backgroundColor:'#59be41',padding:5}}>
            
        </div>
    )
}

export default Preheader
