import React, { useContext, useEffect } from "react";
import AppLogo from "../../layouts/AppLogo/AppLogo";
import AppNav from "../../layouts/AppNav/AppNav";
import Headerimage from "../../layouts/HeaderImage/Headerimage";
import OrgLogos from "../../layouts/OrgLogos/OrgLogos";
import Preheader from "../../layouts/PreHeader/Preheader";
import queryString from "query-string";
import { ValueChainsContext } from "../../contexts/ValueChainsContext/ValueChainsContext";
import AppContainer from "../../layouts/AppContainer/AppContainer";
import AppLeftSideContainer from "../../layouts/AppLeftSideContainer/AppLeftSideContainer";
import AppRightSideContainer from "../../layouts/AppRightSideContainer/AppRightSideContainer";
import AppContentCard from "../../layouts/AppContentCard/AppContentCard";
import { AppContext } from "../../contexts/AppContext/AppContext";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { Link } from "react-router-dom";

function ViewSectionContent({ location }) {
  const { appContentUrl, appVCUrl } = useContext(AppContext);
  const qValue = queryString.parse(location.search);
  const { vcid, sectioncontent_id } = qValue;
  const {
    specificvaluechains,
    setValueChainDetail,
    setContentsFromVC,
    getSectionsDetail,
    getContentFromSection,
    valueChainDetail,
    contentsFromVC,
    isLoading,
    isLoadingContent,
    getSectionContentDetail,
    sectionContentDetail,
  } = useContext(ValueChainsContext);
  useEffect(() => {
    getSectionsDetail(vcid);
    getContentFromSection(vcid);
    getSectionContentDetail(sectioncontent_id);
    return () => {
      // cleanup
      setValueChainDetail([]);
      setContentsFromVC([]);
      // getSectionContentDetail([]);
    };
  }, [appContentUrl, vcid, appVCUrl, sectioncontent_id]);
  return (
    <div>
      <Preheader></Preheader>
      <OrgLogos></OrgLogos>
      <AppLogo appName={valueChainDetail.name} to="/"></AppLogo>
      <AppNav to="/" appName={valueChainDetail.name}></AppNav>
      <Headerimage
        pageHeader={sectionContentDetail.title}
        img={valueChainDetail.cover_image}
      ></Headerimage>
      {/* {
    JSON.stringify(valueChainDetail)
} */}
      <AppContainer>
        <AppLeftSideContainer>
          {isLoadingContent && <LoadingScreen />}

          {sectionContentDetail.length === 0 && isLoadingContent === null ? (
            <span>No Content</span>
          ) : (
            <>
              {sectionContentDetail.content !== undefined  && (
                  <div className="animate__animated animate__fadeIn">
                    <div
                      id="htmldiv"
                      dangerouslySetInnerHTML={{
                        __html: `${sectionContentDetail.content}`,
                      }}
                    />
                  </div>
                )}
            </>
          )}

          {/* <AppContentCard title="title 1" content="content 1" message="message 1" target={`#index1`} index={`index1`}>

      </AppContentCard> */}
        </AppLeftSideContainer>
        <AppRightSideContainer sectioncontent_id={sectioncontent_id} vcid={vcid}></AppRightSideContainer>
      </AppContainer>
    </div>
  );
}

export default ViewSectionContent;
