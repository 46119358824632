import React from "react";
import ContextProviders from "./ContextProviders/ContextProviders";
import AppContextProvider from "./contexts/AppContext/AppContext";
import MainLayout from "./layouts/MainLayout/MainLayout";

function App() {
  return<ContextProviders> <MainLayout></MainLayout></ContextProviders>;
}

export default App;
