import React from 'react'
import { Link } from 'react-router-dom'
function AppLogo(props) {
    return (
        <div id="appLogoDiv" style={{}}>
            <Link id="appLogo" style={{}} to={props.to}>{props.appName}</Link>
        </div>
    )
}

export default AppLogo
