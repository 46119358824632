import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { ValueChainsContext } from "../../contexts/ValueChainsContext/ValueChainsContext";

function AppRightSideContainer(props) {
  const { valuechains, contentsFromVC } = useContext(ValueChainsContext);
  const { vcid, sectioncontent_id } = props;

  return (
    <div class="col-sm-4">
      {contentsFromVC.length !== 0 && sectioncontent_id !== undefined && (
        <>
          <p
          class="nav-link  animate__animated animate__fadeInDown"
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              marginBottom: 0,
              marginTop: 0,
              padding: 5,
            }}
          >
            Related Content
          </p>
          <hr></hr>

          {contentsFromVC
            .filter((val) => {
              return val.id !== parseInt(sectioncontent_id);
            })
            .map((value) => {
              return (
                <Link
                  id="relatedcontentlink"
                  class="nav-link  animate__animated animate__fadeInRight"
                  to={`/viewsectionconetent?vcid=${vcid}&sectioncontent_id=${value.id}`}
                >
                  {value.title}
                  <br></br>
                 
                  
                  <Link
                    id="readmorebtn2"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      fontSize: 10,
                      marginTop:10
                    }}
                    to={`/viewsectionconetent?vcid=${vcid}&sectioncontent_id=${value.id}`}
                    className="btn btn-success btn-sm"
                  >
                    Read more
                  </Link>
                </Link>
              );
            })}
        </>
      )}
      <br></br>
      <br></br>
      <p
        style={{
          fontWeight: "bold",
          textTransform: "uppercase",
          marginBottom: 0,
          marginTop: 0,
          padding: 5,
        }}
      >
        Related Sections
      </p>
      <hr></hr>
      {props.children}
      {valuechains.map((vc) => {
        return (
          <Link
            class="nav-link"
            to={`viewcontent?vcid=${vc.id}&vcName=${vc.name}`}
          >
            {vc.name}
          </Link>
        );
      })}
    </div>
  );
}

export default AppRightSideContainer;
