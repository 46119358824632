import React from "react";
import anime from "./../../images/loading_screen.gif";

function LoadingScreen() {
  return (
    <div style={{ marginTop: 50 }}>
      <center>
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status"></div>
        </div>
        <br></br>
        <span class="">Loading. Please wait...</span>
      </center>
    </div>
  );
}

export default LoadingScreen;
