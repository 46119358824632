import React from 'react'
import AppContextProvider from '../contexts/AppContext/AppContext'
import ValueChainsContextProvider from '../contexts/ValueChainsContext/ValueChainsContext'

function ContextProviders(props) {
    return (
    <>
<AppContextProvider>
    <ValueChainsContextProvider>
        {props.children}
    </ValueChainsContextProvider>
</AppContextProvider>

    </>
    )
}

export default ContextProviders
