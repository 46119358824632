import React, { useContext } from "react";

import { AppContext } from "../../contexts/AppContext/AppContext";
import AppNavEnglishLinks from "./AppNavEnglishLinks";
import AppNavKikuyuLinks from "./AppNavKikuyuLinks";

function AppNav(props) {
  const { appLanguage,isloading } = useContext(AppContext);
  const {appName,to}=props


  return (
    <div>
    <AppNavEnglishLinks appName={appName} to={to}></AppNavEnglishLinks>  
    </div>
  );


}

export default AppNav;
