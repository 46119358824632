import React, { useEffect, useContext, useState, createContext } from "react";
import { AxiosGetService } from "../../Services/AxiosServices/AxiosServices";
import { AppContext } from "../AppContext/AppContext";

export const ValueChainsContext = createContext();

const ValueChainsContextProvider = (props) => {
  const [valuechains, setValueChains] = useState([]);
  const [valueChainDetail, setValueChainDetail] = useState([]);
  const [sectionContentDetail, setsectionContent] = useState([]);

  const [vcisLoading, setvcIsLoading] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingContent, setIsLoadingContent] = useState(null);



  const {
    appContentUrl,
    appVCUrl,
    appLanguageUrl,appSectionUrl,appLanguage
  } = useContext(AppContext);
  const [specificvaluechains, setSpecificValueChains] = useState({
    id: 1,
    name: "Bees"
  });
  const [contentsFromVC, setContentsFromVC] = useState([]);
  const [languages, setLanguages] = useState([]);

  const getSections = async () => {
    // console.log("getting vc")
    setvcIsLoading(true);
    setTimeout(async () => {
      try {
        const getRes = await AxiosGetService(`${appSectionUrl}`);
        const getResData = await getRes;
        setvcIsLoading(true);

        //   console.log();
        setValueChains(getResData.data);
        setvcIsLoading(false);
      } catch (e) {
        setValueChains([]);
        setvcIsLoading(false);
      }
    }, 10);
  };

  const getAppLanguages = async () => {
    // console.log("getting vc")
    setIsLoading(true);
    const getRes = await AxiosGetService(`${appLanguageUrl}`);
    const getResData = await getRes;
    // console.log('****** app langu');

    // console.log(getResData);
    setLanguages(getResData.data);
    setIsLoading(false);
  };

  const getSectionsDetail = async (id) => {
    // console.log("getting vc detail")

    if(id ==="" || id === undefined){
      return 0;
    }

    setValueChainDetail({
      name:"..."
    })
    try {
      setIsLoading(true);
      const getRes = await AxiosGetService(`${appSectionUrl}${id}/`);
      const getResData = await getRes;

      // console.log(getResData.data);
      
        setValueChainDetail(getResData.data);
        setIsLoading(false);
     
    } catch (e) {
      // console.log(e)
      setIsLoading(false);
      // setValueChainDetail([]);
    }
  };


  const getSectionContentDetail = async (id) => {
    // console.log(`getting content detail with id ${id}`)

    if(id === "" || id === undefined){
      // alert(id)

      return 0
    }

    setsectionContent({
      name: "...",
    });
    try {
      setIsLoading(true);
      const getRes = await AxiosGetService(`${appContentUrl}${id}/`);
      const getResData = await getRes;

      // console.log(getResData.data);

      setsectionContent(getResData.data);
      setIsLoading(false);
    } catch (e) {
      // console.log(e);
      setIsLoading(false);
      // setsectionContent([]);
    }
  };

  const getContentFromSection = async (id, url = `${appContentUrl}`) => {
    // console.log("getting vc")
    setIsLoadingContent(true);

    try {
      setTimeout(async() => {
      const getRes = await AxiosGetService(`${url}?section=${id}&language=${parseInt(appLanguage)}`);
      const getResData = await getRes;
      // console.log(getResData.data);
        setContentsFromVC(getResData.data);
        setIsLoadingContent(false);
      }, 10);
     
    } catch (e) {
      // console.log(e)
      // console.log(url)
      // setContentsFromVC([]);

      setIsLoadingContent(false);
    }
  };


  
  useEffect(() => {
    getSections();
    // getContentFromSection()
    // setValueChainAppName(specificvaluechains.name)
  }, [appVCUrl, appContentUrl]);

  useEffect(() => {
    getAppLanguages();
  }, []);
  return (
    <ValueChainsContext.Provider
      value={{
        setValueChainDetail,
        setContentsFromVC,
        valuechains,
        specificvaluechains,
        getSections,
        contentsFromVC,
        getSectionsDetail,
        getContentFromSection,
        valueChainDetail,
        languages,
        isLoading,vcisLoading,isLoadingContent,sectionContentDetail,getSectionContentDetail
      }}
    >
      {props.children}
    </ValueChainsContext.Provider>
  );
};

export default ValueChainsContextProvider;
