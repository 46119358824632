import React from "react";
import { useContext } from "react";
import { AppContext } from "../../contexts/AppContext/AppContext";
import { ValueChainsContext } from "../../contexts/ValueChainsContext/ValueChainsContext";

function Footer(props) {
  const { valuechains } = useContext(ValueChainsContext);

  const date= new Date()
  const fullYear=date.getFullYear()

//   alert(date.getFullYear())
  return (
    <div className="container-fluid" id="footer">
      <br></br> <br></br>
      <div className="container">
        <div className="row">
          <div
            className="col-sm-9"
            style={{ fontSize: 12, marginTop: 20, padding: 20 }}
          >
            <h5 id="" style={{}}>
              {valuechains.map((v, index) => {
                return <span key={index}>{(index ? ", " : "") + v.name}</span>;
              })}{" "}
              Mobile App
            </h5>
            <hr></hr>
            <span> © {fullYear} All Rights Reserved. </span> <br></br>
            <span>
Developed by Kenya Agricultural and Livestock Research Organization
            </span>
            <br></br>
            <span>
              No part of this site may be reproduced without permission.
            </span>
          </div>
        </div>
      </div>
      <br></br>
    </div>
  );
}

export default Footer;
