import React, { useEffect, useContext, useState, createContext } from "react";

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [appLanguage, setappLanguage] = useState("1");
  const [appLanguageShortName, setappLanguageShortName] = useState("ENG");

  const [welcomeMsg, setWelcomeMsg] = useState(null);
  const [ValueChainAppName, setValueChainAppName] = useState("");
  const [isloading, setIsLoading] = useState(null);
  // http://127.0.0.1:8000/
  //197.156.129.158:29
  //http://143.244.157.78:7771/
  const AppIp = ``;
  const OnlineAppIp = `/`;

  const [AppUrl, setApiAppUrl] = useState(`${AppIp}api/siayagaps/`);

  const [appLanguageUrl, setAppLanguageUrl] = useState(
    `${AppUrl}language/`
  );

  const [appSectionUrl, setSectionUrl] = useState(
    `${AppUrl}section/`
  );
  const [englishContentUrl, setEnglishContentUrl] = useState(
    `${AppUrl}englishcontent/`
  );
  const [swahiliContentUrl, setSwahiliContentUrl] = useState(
    `${AppUrl}/swahilicontent/`
  );
  const [englishVCUrl, setEnglishVCUrl] = useState(
    `${AppUrl}valuechain/`
  );
  const [swahiliVCUrl, setSwahiliVCUrl] = useState(
    `${AppUrl}swahilivaluechain/`
  );
  const [appContentUrl, setAppUrl] = useState(`${AppUrl}sectioncontent/`);
  const [appVCUrl, setAppVCUrl] = useState(englishVCUrl);

  const saveOfflineLanguage = (val) => {
    localStorage.setItem("appLang", val);
   
  };

  const saveOfflineAppLanguage = (key, val) => {
    localStorage.setItem(key, val);
   
  };

 

  const changeAppLang = (lang, langUrl, vcUrl, shortName) => {
    setappLanguage(lang);
   

    setAppUrl(`${AppIp}api/siayagaps/${langUrl}`);
    setAppVCUrl(`${AppIp}api/siayagaps/${vcUrl}`);
    setappLanguageShortName(shortName);

    saveOfflineAppLanguage("appLang", lang);
    saveOfflineAppLanguage(
      "appContentLangUrl",
      `${AppIp}api/siayagaps/${langUrl}`
    );
    saveOfflineAppLanguage("appVcUrl", `${AppIp}api/siayagaps/${vcUrl}`);
    saveOfflineAppLanguage("appLangShortName", shortName);

    return 0;
   
  };

 

  const getOfflineLanguage = () => {
    setTimeout(() => {
      if (localStorage.hasOwnProperty("appLang") === true) {
        let appLang = localStorage.getItem("appLang");

      
        setappLanguage(appLang);

      } else {
        setappLanguage("1");
        saveOfflineAppLanguage("appLang", "1");



        setIsLoading(false);
      }
    }, 20);
  };

  const getOfflineVCUrl = () => {
    setTimeout(() => {
      if (localStorage.hasOwnProperty("appVcUrl") === true) {
        let appVCURl = localStorage.getItem("appVcUrl");

        setAppVCUrl(appVCURl);

      } else {
        saveOfflineAppLanguage("appVcUrl", appVCUrl);

      

        setIsLoading(false);
      }
    }, 20);
  };

  const getofflineappContentLangUrl = () => {
    setTimeout(() => {
      if (localStorage.hasOwnProperty("appContentLangUrl") === true) {
        let appContentLangUrl = localStorage.getItem("appContentLangUrl");

        setAppUrl(appContentLangUrl);

   
      } else {
        setAppUrl(`${englishContentUrl}`);
        saveOfflineAppLanguage("appContentLangUrl", englishContentUrl);

  

        setIsLoading(false);
      }
    }, 20);
  };

  const getOfflineLangShortName = () => {
    setTimeout(() => {
      if (localStorage.hasOwnProperty("appLangShortName") === true) {
        let appShortname = localStorage.getItem("appLangShortName");

        setappLanguageShortName(appShortname);


      } else {
        setappLanguageShortName(appLanguageShortName);
        saveOfflineAppLanguage("appLangShortName", appLanguageShortName);


        setIsLoading(false);
      }
    }, 20);
  };

  useEffect(() => {
    getOfflineLanguage();
    // getOfflineVCUrl();
    // getOfflineLangShortName();
    // getofflineappContentLangUrl();
  }, []);
  return (
    <AppContext.Provider
      value={{
        appLanguage,
        setappLanguage,
        saveOfflineLanguage,
        isloading,
        AppUrl,
        welcomeMsg,
        setValueChainAppName,
        englishContentUrl,
        swahiliContentUrl,
        appContentUrl,
        changeAppLang,
        appContentUrl,
        appVCUrl,
        appLanguageUrl,
        appLanguageShortName,appSectionUrl
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
