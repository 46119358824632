import React, { useContext, useEffect } from "react";
import AppLogo from "../../layouts/AppLogo/AppLogo";
import AppNav from "../../layouts/AppNav/AppNav";
import Headerimage from "../../layouts/HeaderImage/Headerimage";
import OrgLogos from "../../layouts/OrgLogos/OrgLogos";
import Preheader from "../../layouts/PreHeader/Preheader";
import queryString from "query-string";
import { ValueChainsContext } from "../../contexts/ValueChainsContext/ValueChainsContext";
import AppContainer from "../../layouts/AppContainer/AppContainer";
import AppLeftSideContainer from "../../layouts/AppLeftSideContainer/AppLeftSideContainer";
import AppRightSideContainer from "../../layouts/AppRightSideContainer/AppRightSideContainer";
import AppContentCard from "../../layouts/AppContentCard/AppContentCard";
import { AppContext } from "../../contexts/AppContext/AppContext";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { Link } from "react-router-dom";

function ViewContent({ location }) {
  const { appContentUrl, appVCUrl } = useContext(AppContext);
  const qValue = queryString.parse(location.search);
  const { vcid, vcName } = qValue;
  const {
    specificvaluechains,
    setValueChainDetail,
    setContentsFromVC,
    getSectionsDetail,
    getContentFromSection,
    valueChainDetail,
    contentsFromVC,
    isLoading,
    isLoadingContent,
  } = useContext(ValueChainsContext);
  useEffect(() => {
    getSectionsDetail(vcid);
    getContentFromSection(vcid);
    return () => {
      // cleanup
      setValueChainDetail([]);
      setContentsFromVC([]);
    };
  }, [appContentUrl, vcid, appVCUrl]);
  return (
    <div>
      <Preheader></Preheader>
      <OrgLogos></OrgLogos>
      <AppLogo appName={valueChainDetail.name} to="/"></AppLogo>
      <AppNav to="/" appName={valueChainDetail.name}></AppNav>
      <Headerimage
        pageHeader={valueChainDetail.name}
        img={valueChainDetail.cover_image}
      ></Headerimage>
      {/* {
    JSON.stringify(valueChainDetail)
} */}
      <AppContainer>
        <AppLeftSideContainer>
          {isLoadingContent && <LoadingScreen />}

          {contentsFromVC.length === 0 && isLoadingContent === null ? (
            <span>
              No Content for the language selected for this value chain.
            </span>
          ) : (
            <>
              {contentsFromVC !== undefined && contentsFromVC.length !== 0 && (
                <>
                  <ul class="mylist">
                    {contentsFromVC.map((value, index) => {
                      return (
                        <Link
                          to={`/viewsectionconetent?vcid=${vcid}&sectioncontent_id=${value.id}`}
                          class="mylist"
                          key={value.id + index}
                        >
                          <div>
                            <h5 id="title">{value.title}</h5>

                            <div
                              id="htmldiv"
                              dangerouslySetInnerHTML={{
                                __html: `${value.intro}`,
                              }}
                            />
                          </div>

                          <Link
                            id="readmorebtn"
                            style={{
                              textDecoration: "none",
                              color: "white",
                              fontSize: 10,
                            }}
                            to={`/viewsectionconetent?vcid=${vcid}&sectioncontent_id=${value.id}`}
                            className="btn btn-success btn-sm"
                          >
                            Read more
                          </Link>
                        </Link>
                      );
                    })}
                  </ul>
                </>
              )}
            </>
          )}

          {/* <AppContentCard title="title 1" content="content 1" message="message 1" target={`#index1`} index={`index1`}>

      </AppContentCard> */}
        </AppLeftSideContainer>
        <AppRightSideContainer vcid={vcid}></AppRightSideContainer>
      </AppContainer>
    </div>
  );
}

export default ViewContent;
